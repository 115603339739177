import { css } from '@emotion/react';
import RadialGauge, { Mode } from 'institutions/components/radial-gauge';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { useAppDispatch } from 'redux/store';
import { LecturePage, NLecturePage } from 'redux/schemas/models/lecture-page';
import {
  CourseOutlineItem, LectureOutlineItem, SectionOutlineItem, SubsectionOutlineItem,
} from 'redux/selectors/course-outline/course-outline-item';
import { CourseOutline, useCourseOutline } from 'redux/selectors/course-outline/use-course-outline';
import { getTimelineSectionProgress } from 'redux/actions/timeline';
import useLectureStatus, { getEstimateReadout, lecturePageSelector, LecturePageStatus } from 'redux/selectors/lecture-page';
import { getCurrentCourse } from 'redux/selectors/course';
import NvIcon from 'shared/components/nv-icon';
import { gray6, gray7, highTide, primary } from 'styles/global_defaults/colors';
import { useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import { AngularContext, AngularServicesContext } from 'react-app';
import NvRouterLink from 'nv-router/nv-link';
import { RootState } from 'redux/schemas';
import { LectureSection } from 'redux/schemas/models/lecture-section';
import Spinner from 'shared/components/spinner';
import { isEmpty } from 'underscore';
import { standardSpacing } from 'styles/global_defaults/scaffolding';
import { CourseTimelineContext } from 'timelines/components/course_home/course-timeline';
import { linkTolecturePage } from '../lecture-page-routes';
import { LecturePageMode } from '..';
import LectureCardViewNavItem from './lecture-card-view-nav-item';
import { config } from '../../../../config/pendo.config.json';

type NavigationItemProps<T extends CourseOutlineItem> = {
  item: T
};

type ExpandedState = { [key: string]: boolean };
type SetExpanded = (subsectionId: number, expanded: boolean, keepExpanded?: boolean) => void;

const currentPageBgColor = 'bg-gray-6';

/** The visual course or collection outline & navigation buttons shown in the lecture page left panel */
export const CourseOutlineNavigation = () => {
  const params = useLecturePageParams();
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const currentTimelineLecturePage = useSelector((state) => state.app.timeline.currentLecturePage);
  const lecturePageId = params.lecturePageId ?? currentTimelineLecturePage?.currentLecture?.activityId;
  const dispatch = useAppDispatch();

  const courseOutline: CourseOutline = useCourseOutline(catalogId);

  const outlineItemKey = (item: CourseOutlineItem) => `${item.type}-${item.id}`;

  const { CurrentPermissionsManager } = useContext(AngularServicesContext);
  const { vieweeId } = useContext(CourseTimelineContext) || {};

  const isCourseAdmin = CurrentPermissionsManager.hasCourseAdminPermissions();

  // Track which lecture section UX'es have been expanded/collapsed
  const [sectionExpandedState, setSectionExpandedState] = useState<ExpandedState>(() => {
    if (!courseOutline) {
      return {};
    }

    const state = {};

    courseOutline.forEach(item => {
      // Hide all lecture pages in subsections by default
      if (item.type === 'subsection') {
        state[outlineItemKey(item)] = false;
      }
    });

    return state;
  });

  /** Visually expands a subsection
   * @param subsectionId ID of the subsection to change
   * @param expanded The new expanded state; false to collapse
   * @param keepExpanded If true, preserves the previous state. False collapses all states by resetting the whole data structure */
  const setItemExpanded = useCallback<SetExpanded>((subsectionId, expanded, keepExpanded) => {
    setSectionExpandedState((state) => ({
      // Ignore the previous state so that *only* the section containing the current lecture page is expanded.
      // Uncommenting the last line will keep states expanded if they were expanded before navigation
      ...(keepExpanded ? state : {}),
      [`subsection-${subsectionId}`]: expanded,
    }));
    if (expanded) {
      dispatch(getTimelineSectionProgress({
        catalogId: params.catalogId,
        sectionId: subsectionId,
        vieweeId,
      }));
    }
  }, [dispatch, params.catalogId, vieweeId]);

  // Expand the outline subsection for the current lecture page, if present
  useEffect(() => {
    // Iterate backwards through the outline array starting at the current lecture item and search for a 'subsection' item.
    // Then, expand that subsection item.
    const currentLectureIndex = courseOutline?.findIndex(outlineItem => outlineItem.type === 'lecture' && outlineItem.id === lecturePageId);
    let parentSubsectionIndex = currentLectureIndex - 1;

    while (parentSubsectionIndex > 0 && courseOutline[parentSubsectionIndex].type === 'lecture') {
      parentSubsectionIndex -= 1;
    }

    if (courseOutline?.[parentSubsectionIndex]?.type === 'subsection') {
      // Force all subsections to collapse upon navigation. We if there's ever a case where we want previously expanded subsections to stay expanded,
      // then this needs to be called with 'true' as the 2nd param
      setItemExpanded(courseOutline[parentSubsectionIndex].id, true, false);
    } else {
      setSectionExpandedState({});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lecturePageId, setItemExpanded]);

  return (
    <div className='mt-4 w-100'>
      {!isEmpty(courseOutline) && courseOutline.map(item => {
        const key = outlineItemKey(item);

        if (!isCourseAdmin && item.type !== 'lecture' && !item.lecturesAreReleased) {
          return null;
        }

        switch (item.type) {
          case 'section':
            return <SectionNavigationItem key={key} item={item} />;
          case 'subsection':
            return <SubsectionNavigationItem key={key} item={item} expanded={sectionExpandedState[key]} setExpanded={setItemExpanded} />;
          case 'lecture':
            return (
              <LectureNavigationItem
                key={key}
                item={item}
                visible={item.sectionType === 'section' || sectionExpandedState[`${item.sectionType}-${item.section}`]}
              />
            );
          default:
            throw new Error(`Invalid item given to CourseOutlineNavigation: ${item}`);
        }
      })}
    </div>
  );
};

/** Navigation UX for individual section-level outline data */
const SectionNavigationItem = (props: NavigationItemProps<SectionOutlineItem>) => {
  const { isFullTimeline } = useContext(CourseTimelineContext) || {};

  return (
    <h1
      className={`${isFullTimeline ? 'card-title' : 'card-title-small'} mt-4 pl-4 pr-4 text-align-center mb-0`}
      data-qa={config.pendo.outline.timeline.sectionRow}
    >
      {props.item.name}
    </h1>
  );
};

/** Navigation UX for individual subsection-level outline data. Is an expandable container for lecture page nav items */
const SubsectionNavigationItem = (props: NavigationItemProps<SubsectionOutlineItem> & {
  /** Whether the section is uncollapsed to show its lecture pages */
  expanded: boolean,
  setExpanded: SetExpanded,
}) => {
  const { isFullTimeline } = useContext(CourseTimelineContext) || {};
  const isProgressLoading = useSelector((state: RootState) => state.app.timeline.loadingLectureSectionsSummaries[props.item.id]);
  const subsection: LectureSection = useSelector((state: RootState) => state.models.lectureSections[props.item.id]);
  const { isContentManagementCollection } = useSelector(getCurrentCourse);

  const styles = css`
    display: flex;
    align-items: center;
    ${isFullTimeline ? css`
      min-height: 60px;
    ` : ''}

    :hover {
      color: ${highTide};
      cursor: pointer;
    }

    .subsection-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin : 0px;
    }
  `;

  const isCompleted = subsection.progress === 'completed';

  const toggleExpanded = () => {
    props.setExpanded(props.item.id, !props.expanded, true);
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      toggleExpanded();
    }
  };
  return (
    <div
      css={styles}
      className={`${isFullTimeline ? 'course-title-small' : 'course-title-xs'} pl-4 pt-2 pb-2 border-bottom border-gray-6`}
      tabIndex={0}
      role='button'
      aria-expanded={props.expanded ? 'true' : 'false'}
      onKeyDown={handleKeyDown}
      onClick={() => toggleExpanded()}
      style={{ opacity: !isContentManagementCollection && !props.item.lecturesAreReleased ? 0.5 : 1 }}
      data-qa={config.pendo.outline.timeline.subsectionRow}
      aria-label={props.item.name}
    >
      <div className='d-flex align-items-baseline w-100 text-truncate'>
        <h3
          className={`subsection-title ${isFullTimeline ? 'course-title-small' : 'course-title-xs'} pr-1`}
          data-qa={config.pendo.outline.timeline.subsectionTitle}
        >
          {props.item.name}
        </h3>
        {isProgressLoading ? (
          <div className='ml-auto pr-2 d-flex align-items-center justify-content-center'>
            <Spinner />
          </div>
        ) : (
          <>
            {isCompleted && (
            <span className='text-success course-title-xxs font-weight-bolder pl-1 pr-2'>
              {t.TIMELINE.COMPLETED()}
            </span>
            )}
            {subsection.totalPoints > 0 && (
            <span className='ml-auto pr-2'>
              <PointsDisplay totalPoints={subsection.totalPoints} pointsReceived={subsection.pointsReceived} />
            </span>
            )}
          </>
        )}
      </div>
      <div className='ml-auto pr-2'>
        <NvIcon
          icon={props.expanded ? 'arrow-up' : 'arrow-down'}
          size='xss'
          data-qa={props.expanded ? 'subsection-collapse' : 'subsection-expand'}
        />
      </div>
    </div>
  );
};

/** Navigation UX for individual lecture-level outline data. Is a clickable button that performs lecture page navigation */
const LectureNavigationItem = (props: NavigationItemProps<LectureOutlineItem> & {
  /** Whether this item can be seen because the parent subsection is expanded */
  visible: boolean,
}) => {
  const params = useLecturePageParams();
  const lecturePage = useSelector(state => state.models.lecturePages[props.item.id]);
  const lecturePageStatus = useLectureStatus(lecturePage.id);
  const catalogId = useSelector((state) => state.app.currentCatalogId);
  const isCardView = useSelector(state => state.models.courses[state.app.currentCatalogId].cardView);
  const isCurrentLectureLoading = useSelector((state) => state.app.timeline.isCurrentLectureLoading);
  const currentTimelineLecturePage = useSelector((state) => state.app.timeline.currentLecturePage);
  const { isContentManagementCollection } = useSelector(getCurrentCourse);
  const isLastVisitedItem = !isCurrentLectureLoading
    && props.item.id === currentTimelineLecturePage?.currentLecture?.activityId;

  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const { isFullTimeline } = useContext(CourseTimelineContext) || {};

  const isCurrentPage = isFullTimeline
    ? isLastVisitedItem
    : params.lecturePageId === props.item.id;

  if (!props.visible) {
    return null;
  }

  const NavigationItem = () => (
    <div style={{ opacity: !isContentManagementCollection && !lecturePage.released ? 0.5 : 1, flex: 1 }}>
      <NonCardViewNavItem
        isCurrentPage={isCurrentPage}
        lecturePage={lecturePage}
        lecturePageStatus={lecturePageStatus}
        {...props}
      />
      {/* Lecture page left panel "card" view removed as per the JIRA card NOV-75168.
        We can use the git commit history of this card if we need the card view in the future. */}
    </div>
  );

  if (isFullTimeline) {
    /**
     * If it is course home, use <a> tag to navigate to the lecture page as is
     * it is outside of the router. But for lecture page we can use NvRouterLink,
     * because the whole lecture page environment resides in React and NvRouter
     * wrappered around it
     */
    return (
      <a href={$state.href(
        'lecture-page',
        {
          catalogId,
          id: lecturePage.id,
        },
      )}
      >
        {isCardView ? (
          <LectureCardViewNavItem
            isCurrentPage={isCurrentPage}
            lecturePage={lecturePage}
            lecturePageStatus={lecturePageStatus}
            {...props}
          />
        ) : <NavigationItem /> }
      </a>
    );
  }

  return (
    <NvRouterLink
      to={linkTolecturePage({
        ...params,
        lecturePageId: lecturePage.id,
      })}
      /** These are rendered as <a> elements, which by default show text-primary colored text when focused */
      css={css`
        :focus {
          color: unset;
        }
      `}
      id={`timeline-lecture-page-${lecturePage.id}`}
      aria-expanded={isCurrentPage}
    >
      <NavigationItem />
    </NvRouterLink>
  );
};

/** Styles for both the card and non-card view UXes below */
const lectureNavigationItemStyles = css`
  &:hover {
    cursor: pointer;
    .item-name {
      color: ${highTide};
    }
  }

  .icon-panel {
    /* TODO: I'm really not a fan of setting a minwidth on this to achieve the correct offset. Let's revist this */
    min-width: 60px;
  }
`;

const nonCardLectureNavItemStyles = css`
  ${lectureNavigationItemStyles};
  min-height: 60px;
  border-bottom: 1px dashed ${gray6};

  .linked-lesson {
    min-width: ${standardSpacing}px;
    height: ${standardSpacing}px;
  }
`;

export type InnerLectureNavigationItemProps = NavigationItemProps<LectureOutlineItem> & {
  isCurrentPage: boolean,
  /** TODO: Use this to retrieve the custom color & image options for this nav item */
  lecturePage: NLecturePage,
  lecturePageStatus: LecturePageStatus,
};

const NonCardViewNavItem = (props: InnerLectureNavigationItemProps) => {
  const isProgressLoading = useSelector((state: RootState) => state.app.timeline.loadingLectureSections[props.lecturePage.lectureSectionId]);
  const lecturePage: LecturePage = useSelector((state: RootState) => lecturePageSelector(state, props.lecturePage.id));
  const params = useLecturePageParams();

  const { isFullTimeline } = useContext(CourseTimelineContext) || {};

  return (
    <div
      css={nonCardLectureNavItemStyles}
      className={`d-flex align-items-center justify-content-between pt-2 pb-2 pl-4 pr-4 ${props.isCurrentPage ? currentPageBgColor : ''}`}
      data-qa={config.pendo.outline.timeline.lessonRow}
    >
      <div className='d-flex align-items-center'>
        {/* TODO: I'm really not a fan of setting a minwidth on this to achieve the correct offset. Placed in a style here as a reminder to rework */}
        <div className={`icon-panel pl-2 pr-2 ${props.isCurrentPage ? 'text-primary' : ''}`}>
          <OutlineIcon
            size='smallest'
            lecturePageStatus={props.lecturePageStatus}
            isCurrentPage={props.isCurrentPage}
          />

        </div>
        <div className={`item-name ${isFullTimeline ? 'd-flex align-items-center' : ''}`}>
          <div className='mr-1 '>
            <h2
              className={`text-medium mb-0 mt-0 ${props.isCurrentPage ? 'font-weight-bold' : ''}`}
              data-lhs-lecturepage-name={props.item.name}
              data-qa={config.pendo.outline.timeline.lessonTitle}
            >
              {props.item.name}
            </h2>
            { !props.lecturePage.released
          && (
          <span className='ml-1 text-small condensed'>
            {t.TIMELINE.RELEASE_DATE(moment(props.lecturePage.releaseDate).format('MM/DD/YYYY'))}
          </span>
          )}
          </div>

          {/* ONly show this section if there are points or estimate text to display.
        TODO: Unify these checks with the same checks inside this tag */}
          {(lecturePage.totalPoints?.[0] > 0 || props.lecturePage.estimation?.estimatedEffort > 0) && (
          <div className='mt-1 d-flex align-items-baseline'>
            <span className='text-gray-2 text-small mr-1'>
              {props.lecturePage.estimation?.estimatedEffort > 0 && getEstimateReadout(props.lecturePage.estimation)}
            </span>
            {lecturePage.totalPoints?.[0] > 0 && (
            <div className='pr-2'>
              <PointsDisplay totalPoints={lecturePage.totalPoints?.[0]} pointsReceived={lecturePage.pointsReceived} />
            </div>
            )}
          </div>
          )}
        </div>
      </div>
      {isProgressLoading && (
      <div className='d-flex align-items-center justify-content-center'>
        <Spinner />
      </div>
      )}
      {isFullTimeline && props.isCurrentPage && (
        <div className='course-title-xxs text-black'>
          {t.TIMELINE.LAST_VISITED()}
        </div>
      )}
      { /* TODO: Verify that this time translates correctly. This doesn't use the moment format codes defined in app.js; the Angularjs app uses `'MOMENT.MONTH_DAY_YEAR'`, here */}
      {props.item.isLinked && params.mode !== LecturePageMode.VIEW && (
        <div className='linked-lesson d-flex align-items-center justify-content-center rounded-circle bg-primary ml-auto'>
          <NvIcon icon='format-makelink' size='xss-smallest' />
        </div>
      )}
    </div>
  );
};

const OutlineIcon = (props: {
  lecturePageStatus: LecturePageStatus,
  isCurrentPage: boolean,
  size: string,
}) => (
  <div className={`${(props.lecturePageStatus === 'completed') ? 'text-success' : ''} text-center`}>
    { props.lecturePageStatus === 'in-progress'
      && <InProgressRadialGauge isCurrentPage={props.isCurrentPage} isCardView={false} />}
    { props.lecturePageStatus !== 'in-progress'
      && <NvIcon size={props.size} icon={props.lecturePageStatus === 'completed' ? 'check' : 'read'} />}
  </div>
);

export const PointsDisplay = (props: { totalPoints: number, pointsReceived: number }) => {
  const { totalPoints, pointsReceived } = props;
  const params = useLecturePageParams();

  const showPoints = totalPoints > 0;
  const showCurrentPoints = params.mode === LecturePageMode.VIEW && pointsReceived > 0;

  if (!showPoints) {
    return null;
  }

  return (
    <span className='text-small text-gray-2 d-flex align-items-baseline'>

      <span className={`${showCurrentPoints ? 'text-success' : ''} mr-1`}>
        <NvIcon size='xss-smallest' icon={showCurrentPoints ? 'highlight' : 'points'} />
      </span>
      {(!showCurrentPoints || pointsReceived === 0) && (
        <span className='font-weight-bolder'>{totalPoints}</span>
      )}

      {showCurrentPoints && pointsReceived > 0
        && pointsReceived < totalPoints && (
        <span>
          <span className='text-success font-weight-bold'>{pointsReceived}</span>
          <span className='text-xsmall font-weight-bold'>
            /
            {totalPoints}
          </span>
        </span>
      )}

      {showCurrentPoints && pointsReceived >= totalPoints && (
        <span className='font-weight-bolder text-success'>{pointsReceived}</span>
      )}
    </span>
  );
};

const InProgressRadialGauge = (props: {
  isCurrentPage: boolean,
  isCardView: boolean,
}) => {
  let bkgColor = null;

  if (props.isCardView) {
    bkgColor = props.isCurrentPage ? gray6 : 'white';
  } else {
    bkgColor = props.isCurrentPage ? gray6 : gray7;
  }

  return (
    <RadialGauge
      activeColor={primary}
      bkgColor={bkgColor}
      // This is currently hardcoded to 50% completion; we do not do dynamic calulations on completion %
      // of these displays for historical reasons, but this can change in the future
      current={50}
      max={100}
      min={0}
      mode={Mode.FULL_CIRCLE}
      labelsDisabled
      width={36}
      meterThickness={2}
      iconClass='read'
    />
  );
};

export default CourseOutlineNavigation;
