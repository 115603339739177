import { jsx, css } from '@emotion/react';
import LectureComponentProvider, { useLectureComponentContext } from 'components/lecture-component-provider';
import t from 'react-translate';
import ActivityStatus from 'shared/components/activity/activity-status';
import ActivityTitle from 'shared/components/activity/activity-title';
import { ActivityProgress } from 'redux/schemas/models/activity';
import { halfSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import React from 'react';
import { useSelector } from 'react-redux';
import { LectureComponentProps, LecturePageMode } from '..';
import PollHeaderPointsConfiguration from './poll-header-points-configuration';
import PollOptions from './poll-options';
import PollQuestion from './poll-question';
import { useGetPollLectureComponent, usePollEditDropdown, useVotesPushNotificationSubscriber } from './poll-utils';
import PollTitle from './poll-title';
import PollDeadline from './poll-deadline';

/* @ngInject */
export default function PollLectureComponentContextWrapper(props: LectureComponentProps) {
  const currentCatalogId = useSelector(state => state.app.currentCatalogId);
  const isEdit = props.mode === LecturePageMode.EDIT;
  const isDeadlineEditable = props.mode === LecturePageMode.EDIT || props.mode === LecturePageMode.LINKED_EDIT;

  return (
    <LectureComponentProvider
      catalogId={currentCatalogId}
      lectureComponentId={props.lectureComponent.id}
      lecturePageId={props.currentLecture.id}
      isEdit={isEdit}
      angularComponent={props.angularComponent}
      isDeadlineEditable={isDeadlineEditable}
    >
      <PollLectureComponent {...props} />
    </LectureComponentProvider>
  );
}

function PollLectureComponent(props: LectureComponentProps) {
  const lectureComponent = useGetPollLectureComponent();
  const currentCatalogId = useSelector(state => state.app.currentCatalogId);
  const { isEdit } = useLectureComponentContext();

  // Edit dropdown poll options
  usePollEditDropdown(lectureComponent);
  // subscribe for new votes
  useVotesPushNotificationSubscriber();

  // TODO: Update this to the new normalized format
  const [questionId] = lectureComponent.poll.questionIds;

  return (
    <div id={`lecture-component-${props.lectureComponent.id}`}>
      <div css={headerContainerStyle}>
        <div className='activity-status'>
          <ActivityStatus icon='polls' status={lectureComponent.poll.progress} />
        </div>
        <ActivityTitle
          status={lectureComponent.poll.progress}
          statusesTexts={{
            not_started: t.LECTURE_PAGES.COMPONENTS.POLLS.HEADER_TAKE_TITLE(),
          }}
          isAriaHidden
        />

        <PollTitle lectureComponent={lectureComponent} />

        {lectureComponent.poll.pointsConfigurationId && (
          <PollHeaderPointsConfiguration
            pointsReceived={lectureComponent.poll.pointsReceived}
            pointsConfigurationId={lectureComponent.poll.pointsConfigurationId}
          />
        )}

        {/* deadline */}
        <PollDeadline />
      </div>

      <PollQuestion pollQuestionId={questionId} />

      <PollOptions pollQuestionId={questionId} catalogId={currentCatalogId} pollId={lectureComponent.poll.id} />
    </div>
  );
}

const headerContainerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${standardSpacing}px;

  .activity-status {
    margin-bottom: ${halfSpacing}px;
  }
  .title-input-wrapper {
    align-self: stretch;
  }
`;
